<template>
  <div>
    <!-- login-form-->
    <form>
      <div class="login-box">
        <h1>
          <p><strong>RedWood Health</strong> Care Platform</p>
        </h1>
        <fieldset>
          <input
            v-model="userId"
            type="text"
            class="input-id"
            tabindex="1"
            maxlength="100"
            placeholder="ID"
            @keyup.enter="$refs.password.focus()"
          />
          <input
            v-model="password"
            type="password"
            ref="password"
            placeholder="Password"
            class="input-pw"
            tabindex="2"
            maxlength="30"
            @keyup.enter="onKeyEnter('password')"
          />
          <a
            class="login-btn"
            tabindex="3"
            @click="loginProcess()"
            style="color: white"
            >LOGIN</a
          >
        </fieldset>
        <p class="login_copytxt">Copyright 2018 DKI Technology Co,.Ltd.</p>
      </div>
    </form>

    <!-- 비밀번호변경 팝업 -->
    <div v-show="changePwPopup.show">
      <div class="popup-mask"></div>
      <div class="pop-content pop-ani pop-drag panel" style="width:400px">
        <div class="pop-head">
          <h2>{{ $t('login.changePwPopUp.title') }}</h2>
        </div>
        <div class="pop-body">
          <!-- 안내 -->
          <validation-observer ref="observer">
            <div class="font-13">
              <label>
                {{ $t('login.changePwPopUp.label1') }}:
                <span class="c-orange">{{
                  changePwPopup.loginResult.userId
                }}</span>
              </label>
              <br /><br />
              <div>
                <p v-html="$i18n.t('login.changePwPopUp.desc')"></p>
                <p class="c-orange mt-10">
                  {{
                    $t('login.changePwPopUp.input-guide', {
                      min: 9,
                      max: 30,
                    })
                  }}
                </p>
                <br />
              </div>
            </div>
            <!-- 폼 -->
            <form id="changePasswordPopForm">
              <table class="table-style-default table-pop">
                <colgroup>
                  <col width="45%" />
                  <col width="*" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>{{ oldPw }}<i class="star">*</i></th>
                    <td>
                      <validate-form
                        type="password"
                        :label="oldPw"
                        :rules="{
                          required: true,
                          regex: /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{9,30}$/,
                          min: 9,
                          max: 30,
                        }"
                        :errorData="pwInputErr"
                        inputClass="input-24 w-100"
                        maxlength="60"
                        v-model="changePwPopup.password"
                        dataType="PASSWORD"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <table class="table-style-default table-pop mt-10">
                <colgroup>
                  <col width="45%" />
                  <col width="*" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>{{ newPw }}<i class="star">*</i></th>
                    <td>
                      <validate-form
                        type="password"
                        :label="newPw"
                        :rules="{
                          required: true,
                          regex: /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{9,30}$/,
                          min: 9,
                          max: 30,
                        }"
                        :errorData="pwInputErr"
                        inputClass="input-24 w-100"
                        maxlength="30"
                        v-model="changePwPopup.newPassword"
                        dataType="PASSWORD"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>{{ newPwCheck }}<i class="star">*</i></th>
                    <td>
                      <validate-form
                        type="password"
                        :label="newPwCheck"
                        :rules="{
                          required: true,
                          confirmed: newPw,
                        }"
                        inputClass="input-24 w-100"
                        maxlength="60"
                        dataType="PASSWORD"
                        v-model="changePwPopup.newPasswordChk"
                        @keyup.enter="onKeyEnter('newPassword')"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
            <p class="pop-btn-group">
              <input
                type="button"
                :value="btnSubmit"
                class="bt-line30 cr-orange"
                @click="changePassword"
              />
            </p>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonMixins from '@/mixins/CommonMixins';
import ValidateForm from '@/components/ValidateForm.vue';
import {
  duplicateLoginChk,
  deleteLoginStatus,
  loginProcess,
  changePassword,
} from '@/api/loginApi';

export default {
  components: { ValidateForm },
  mixins: [CommonMixins],
  data() {
    return {
      userId: '',
      password: '',
      changePwPopup: {
        show: false,
        loginResult: '',
        password: '',
        newPassword: '',
        newPasswordChk: '',
      },
    };
  },
  computed: {
    loginInfo() {
      return { userId: this.userId, password: this.password };
    },
    oldPw() {
      return this.$i18n.t('login.changePwPopUp.label2');
    },
    newPw() {
      return this.$i18n.t('login.changePwPopUp.label3');
    },
    newPwCheck() {
      return this.$i18n.t('login.changePwPopUp.label4');
    },
    pwInputErr() {
      return { regex: this.$i18n.t('common.validate.password-format') };
    },
    btnSubmit() {
      return this.$i18n.t('common.btn.submit');
    },
  },
  methods: {
    async loginProcess() {
      // validate
      if (this.validate()) {
        // 중복 로그인 체크
        const { data } = await duplicateLoginChk(this.userId);
        if (!data.valid) {
          // 중복 로그인 삭제 여부
          this.$dialogs
            .confirm(this.jsonEscape(data.message))
            .then(({ ok }) => {
              if (ok) {
                this.deleteLoginStatus();
              }
            });
        } else {
          // 로그인
          this.login();
        }
      }
    },
    // 로그인 기록 제거
    async deleteLoginStatus() {
      await deleteLoginStatus(this.loginInfo).then(({ data }) => {
        this.$dialogs.alert(this.jsonEscape(data.message));
      });
    },
    // 로그인
    async login() {
      await loginProcess(this.loginInfo).then(({ data }) => {
        const { valid, firstLogin, needToModifyPassword } = data;
        if (!valid) {
          this.$dialogs.alert(this.jsonEscape(data.message));
        } else if (firstLogin || needToModifyPassword) {
          this.openChangePwPopup(data);
        } else {
          // 로그인 성공
          this.processLoginSuccessful(data);
        }
      });
    },
    // 비밀번호 변경
    async changePassword() {
      const result = await this.$refs.observer.validate();
      if (!result) {
        this.$dialogs.alert(this.$i18n.t('common.alert.required'));
        return;
      }

      const pwdType = this.changePwPopup.loginResult.firstLogin ? 'fir' : 'mod';
      const token = this.changePwPopup.loginResult.token;
      const payload = {
        userId: this.changePwPopup.loginResult.userId,
        userName: this.changePwPopup.loginResult.userName,
        password: this.changePwPopup.password,
        newPassword: this.changePwPopup.newPassword,
        newPasswordRe: this.changePwPopup.newPasswordChk,
      };

      // api
      await changePassword(pwdType, token, payload).then(res => {
        const { status, data } = res;
        // status 200
        if (status == 200 && data.valid) {
          this.$dialogs
            .alert(this.$i18n.t('common.alert.update-success'))
            .then(() => {
              // 로그인 성공
              this.processLoginSuccessful(this.changePwPopup.loginResult);
            });
        } else if (status == 400 && data.message == 'invalid password') {
          this.$dialogs.alert(this.$i18n.t('common.alert.invalid-pw'));
        } else {
          // 이 외의 실패의 경우, message 있을 때 message 출력
          this.$dialogs.alert(
            data.message
              ? data.message
              : `${this.$i18n.t('common.alert.update-fail')}<br/>${this.$i18n.t(
                  'common.alert.ask-admin',
                )}`,
          );
        }
      });
    },
    // 인증 정보 store 저장
    async processLoginSuccessful(loginResult) {
      if (!loginResult.firstMenuUrl) {
        this.$dialogs.alert(this.$i18n.t('common.alert.noMenu'));
        return;
      }
      // store에 로그인 정보 저장
      await this.$store.commit('auth/SET_LOGIN', loginResult);
      await this.$store.dispatch('auth/FETCH_MENU_LIST').then(() => {
        const split = loginResult.firstMenuUrl.split('/');
        this.$router.push(`/${split[2]}/${split[3]}`);
      });
    },
    // validate
    validate() {
      if (!this.userId) {
        const field = this.getPostWord(this.$i18n.t('login.id'), '을', '를');
        this.$dialogs.alert(
          this.$i18n.t('common.alert.required-field', {
            field,
          }),
        );
        return false;
      }
      if (!this.password) {
        const field = this.getPostWord(this.$i18n.t('login.pw'), '을', '를');
        this.$dialogs.alert(
          this.$i18n.t('common.alert.required-field', {
            field,
          }),
        );
        return false;
      }
      return true;
    },
    // 비밀번호 변경 팝업 open
    openChangePwPopup(data) {
      this.changePwPopup.loginResult = data;
      this.changePwPopup.show = true;
    },
    // keyUpEnter: enter 클릭 시, 팝업 없는 경우에만 loginProcess()
    onKeyEnter(type) {
      if (
        type == 'newPassword' &&
        !document.getElementsByClassName('popup').length
      ) {
        this.changePassword();
      } else {
        if (!document.getElementsByClassName('popup').length) {
          this.loginProcess();
        }
      }
    },
  },
};
</script>

<style scoped>
input {
  background: white;
}
</style>
