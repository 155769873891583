<template>
  <div class="login-body">
    <div class="login-bg"></div>
    <h1 class="company-ci">
      <img
        :src="require(`@/assets/images/img_top/logo_gnb.png`)"
        alt="RedWood Health"
        style="max-width:400px"
      />
    </h1>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'IntroLayout',
};
</script>

<style lang="scss" scoped></style>
